import React, { useState } from 'react';

const WoodenFish = () => {
    const [textList, setTextList] = useState([]); // To handle floating text
    const [count, setCount] = useState(0); // To track the number of clicks

    // Handle wooden fish click
    const handleClick = () => {
        // Trigger wooden fish zoom
        const woodenFishElement = document.querySelector("#wooden-fish");
        if (woodenFishElement) {
            woodenFishElement.classList.add("w-f-c-i-size");
            setTimeout(() => {
                woodenFishElement.classList.remove("w-f-c-i-size");
            }, 300);
        }

        // Create floating text "功德+1"
        const newText = {
            id: count,
            text: "功德+1",
        };
        setTextList([...textList, newText]);
        setCount(count + 1);

        // Remove the text after 2 seconds
        setTimeout(() => {
            setTextList((prev) => prev.filter((text) => text.id !== newText.id));
        }, 2000);

        // Play sound (ensure audio element is available and loaded)
        const audioElement = document.querySelector("#wooden-fish-audio");
        if (audioElement) {
            audioElement.play().catch((err) => {
                console.error('Error playing audio:', err);
            });
        }

        // Trigger vibration (if supported)
        if (navigator && navigator.vibrate) {
            navigator.vibrate(300);
        }
    };

    return (
        <div className="wooden-fish-container">
            <img
                src="/images/wooden-fish.png" // Replace with your wooden fish image
                alt="Wooden Fish"
                id="wooden-fish"
                onClick={handleClick}
            />
            <div className="w-f-c-text">
                {textList.map((text) => (
                    <p key={text.id} className="floating-text fade-out">
                        {text.text}
                    </p>
                ))}
            </div>
            <audio src="/sounds/wooden-fish-sound.mp3" id="wooden-fish-audio" preload="auto"></audio> {/* Replace with your sound file */}
        </div>
    );
};

export default WoodenFish;
