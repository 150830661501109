import React from 'react';
import './App.css';
import PortfolioDashboard from './components/PortfolioDashboard';

function App() {
  return (
    <div className="App">
      <PortfolioDashboard />
    </div>
  );
}

export default App;
