import React, { useState, useEffect } from 'react';
import WoodenFish from './WoodenFish';
import BalanceChart from './BalanceChart';
import './PortfolioDashboard.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const PortfolioDashboard = () => {
    const [walletBalance, setWalletBalance] = useState(0);
    const [profit, setProfit] = useState(0);
    const [roi, setROI] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [positions, setPositions] = useState([]);
    const [accountType, setAccountType] = useState('UNIFIED');
    const initialInvestment = parseFloat(process.env.REACT_APP_INITIAL_INVESTMENT || 5000);

    useEffect(() => {
        const fetchWalletBalance = async () => {
            const api_key = process.env.REACT_APP_BYBIT_API_KEY;
            const api_secret = process.env.REACT_APP_BYBIT_API_SECRET;
            const recv_window = '5000';
            const timestamp = Date.now().toString();

            const balanceParams = {
                accountType: accountType,
                api_key: api_key,
                recv_window: recv_window,
                timestamp: timestamp,
            };

            const balanceQueryString = Object.keys(balanceParams)
                .sort()
                .map(key => key + '=' + balanceParams[key])
                .join('&');

            const balanceSignature = CryptoJS.HmacSHA256(balanceQueryString, api_secret).toString();
            const urlBalance = `https://api.bybit.com/v5/account/wallet-balance?${balanceQueryString}&sign=${balanceSignature}`;

            try {
                const responseBalance = await axios.get(urlBalance);
                if (responseBalance.data && responseBalance.data.result.list) {
                    const balanceList = responseBalance.data.result.list;
                    const balance = parseFloat(balanceList[0].totalEquity);
                    setWalletBalance(balance);
                    const currentProfit = balance - initialInvestment;
                    setProfit(currentProfit);
                    setROI((currentProfit / initialInvestment) * 100);
                    setChartData(prevData => [
                        ...prevData,
                        {
                            time: new Date().toLocaleTimeString(),
                            balance: balance,
                            profit: currentProfit,
                        },
                    ]);
                }
            } catch (error) {
                console.error("Error fetching wallet balance:", error);
            }
        };

        const fetchOpenPositions = async () => {
            const api_key = process.env.REACT_APP_BYBIT_API_KEY;
            const api_secret = process.env.REACT_APP_BYBIT_API_SECRET;
            const recv_window = '5000';
            const timestamp = Date.now().toString();

            const positionParams = {
                category: 'linear',  // linear for perpetual contracts
                symbol: 'BTCUSDT',   // symbol for BTC/USDT perpetual
                api_key: api_key,
                recv_window: recv_window,
                timestamp: timestamp,
            };

            const positionQueryString = Object.keys(positionParams)
                .sort()
                .map(key => key + '=' + positionParams[key])
                .join('&');

            const positionSignature = CryptoJS.HmacSHA256(positionQueryString, api_secret).toString();
            const urlPositions = `https://api.bybit.com/v5/position/list?${positionQueryString}&sign=${positionSignature}`;

            try {
                const responsePositions = await axios.get(urlPositions);
                if (responsePositions.data && responsePositions.data.result.list) {
                    setPositions(responsePositions.data.result.list);
                }
            } catch (error) {
                console.error("Error fetching open positions:", error);
            }
        };

        fetchWalletBalance();
        fetchOpenPositions();
    }, [initialInvestment, walletBalance, profit, accountType]);

    return (
        <div className="dashboard">
            <h1>Portfolio Dashboard</h1>
            <div>
                <label htmlFor="accountType">Select Account Type: </label>
                <select
                    id="accountType"
                    value={accountType}
                    onChange={(e) => setAccountType(e.target.value)}
                >
                    <option value="UNIFIED">Unified</option>
                    <option value="DERIVATIVES">Derivatives</option>
                    <option value="SPOT">Spot</option>
                    <option value="linear">Perpetual (BTC/USDT)</option>
                </select>
            </div>

            <h2>Total Balance: ${walletBalance.toFixed(2)}</h2>
            <h3>Profit: ${profit.toFixed(2)}</h3>
            <h3>ROI: {roi.toFixed(2)}%</h3>
            <BalanceChart data={chartData} />

            <div>
                <h2>Open Positions</h2>
                <table className="positions-table">
                    <thead>
                        <tr>
                            <th>Symbol</th>
                            <th>Position Size</th>
                            <th>Value (USD)</th>
                            <th>Entry Price</th>
                            <th>Current Price</th>
                            <th>Unrealized P&L</th>
                        </tr>
                    </thead>
                    <tbody>
                        {positions.length > 0 ? (
                            positions.map((position, index) => (
                                <tr key={index}>
                                    {/* Applying long or short position class */}
                                    <td className={position.side === 'Buy' ? 'long-position' : 'short-position'}>
                                        {position.symbol}
                                    </td>
                                    <td>{position.size}</td>
                                    <td>${(position.size * position.markPrice).toFixed(2)}</td>
                                    <td>{position.avgPrice}</td>
                                    <td>{position.markPrice}</td>
                                    {/* Applying positive or negative P&L class */}
                                    <td className={parseFloat(position.unrealisedPnl) > 0 ? 'positive-pnl' : 'negative-pnl'}>
                                        {position.unrealisedPnl}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No open positions found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            <WoodenFish />
        </div>
    );
};

export default PortfolioDashboard;
