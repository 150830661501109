import React from 'react';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BalanceChart = ({ xAxisProps = { dataKey: 'time' }, yAxisProps = { domain: ['auto', 'auto'] }, data }) => {
    return (
        <ResponsiveContainer width="100%" height={400}>
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis {...xAxisProps} />
                <YAxis {...yAxisProps} />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="balance" stroke="#8884d8" />
                <Line type="monotone" dataKey="profit" stroke="#82ca9d" />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default BalanceChart;
